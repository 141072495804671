export const titulos = [
  {
    author: "Eduardo Spohr",
    color: "#1a237e",
    colorFont: "white",
    comment: "Master pieace.",
    description: "Discription example...",
    font: '"Bebas Neue", cursive',
    heigth: "130px",
    id: 1,
    img: "./img/abatalhadoapocalipse.jpg",
    rating: "3",
    texture: "url(https://www.transparenttextures.com/patterns/black-orchid.png)",
    title: "A Batalha do Apocalípse",
  },
  {
    author: "Nathalia Arcuri",
    color: "#3949ab",
    colorFont: "white",
    comment: "...",
    description: "...",
    font: '"Dancing Script", cursive',
    heigth: "100px",
    id: 2,
    img: "./img/mepoupe.jpg",
    rating: "4",
    texture: "url(https://www.transparenttextures.com/patterns/dark-exa.png)",
    title: "Me Poupe!",
  },
  {
    author: "Khaled Hosseini",
    color: "#7986cb",
    colorFont: "black",
    comment: "...",
    description: "...",
    font: '"Dancing Script", cursive',
    heigth: "120px",
    id: 3,
    img: "./img/ocacadordepipas.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/dark-exa.png)",
    title: "O Caçador de Pipas"
  },
  {
    author: "Dan Brown",
    color: "#9fa8da",
    colorFont: "black",
    comment: "More of the same.",
    description: "Inferno is a 2013 mystery thriller novel by American author Dan Brown and the fourth book in his Robert Langdon series, following Angels & Demons...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 4,
    img: "./img/inferno.jpg",
    rating: "2",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "Inferno",
  },
  {
    author: "George R. R. Martin",
    color: "#1a237e",
    colorFont: "white",
    comment: "....",
    description: "...",
    font: '"Bebas Neue", cursive',
    heigth: "130px",
    id: 5,
    img: "./img/guerradostronos.jpg",
    rating: "4",
    texture: "url(https://www.transparenttextures.com/patterns/black-orchid.png)",
    title: "Gelo e Fogo",
  },
  {
    author: "Max Tegmark",
    color: "#9fa8da",
    colorFont: "black",
    comment: "...",
    description: "...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 6,
    img: "./img/life30.jpeg",
    rating: "2",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "Life 3.0: Being Human in the Age of Artificial Intelligence",
  },
  {
    author: "Andy Puddicombe",
    color: "#7986cb",
    colorFont: "black",
    comment: "...",
    description: "The Headspace Guide to Meditation and Mindfulness offers simple but powerful meditation techniques that positively impact every area of physical and mental health: from productivity and focus, to stress and anxiety relief, sleep, weight-loss, personal relationships...the benefits are limitless.",
    font: '"Dancing Script", cursive',
    heigth: "120px",
    id: 7,
    img: "./img/theheadspace.jpg",
    rating: "4",
    texture: "url(https://www.transparenttextures.com/patterns/dark-exa.png)",
    title: "The Headspace Guide to Meditation and Mindfulness"
  },
  {
    author: "Douglas Adams",
    color: "#9fa8da",
    colorFont: "black",
    comment: "...",
    description: "In one complete volume, here are the five classic novels from Douglas Adams’s beloved Hitchhiker series.",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 8,
    img: "./img/douglasadams.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "The Ultimate Hitchhiker's Guide To The Galaxy",
  },
  {
    author: "William H. McRaven",
    color: "#3949ab",
    colorFont: "white",
    comment: "...",
    description: "Arrume sua cama. Pequenas atitudes que podem mudar a sua vida... E talvez o mundo...",
    font: '"Dancing Script", cursive',
    heigth: "100px",
    id: 9,
    img: "./img/arrumeasuacama.jpg",
    rating: "3",
    texture: "url(https://www.transparenttextures.com/patterns/dark-exa.png)",
    title: "Arrume A Sua Cama",
  }, 
  {
    author: "Chimamanda Ngozi Adichie",
    color: "#7986cb",
    colorFont: "black",
    comment: "...",
    description: "Chimamanda Ngozi Adichie ainda se lembra exatamente do diaem que a chamaram de feminista pela primeira vez. Foi duranteuma discussão com seu amigo...",
    font: '"Dancing Script", cursive',
    heigth: "120px",
    id: 10,
    img: "./img/sejamostodosfeministas.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/dark-exa.png)",
    title: "Sejamos Todos Feministas"
  },
  {
    author: "Djamila Ribeiro",
    color: "#9fa8da",
    colorFont: "black",
    comment: "...",
    description: "Dez lições breves para entender as origens do racismo e como combatê-lo...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 11,
    img: "./img/pequenomanualantirracista.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "Pequeno Manual Antirracista",
  },
  {
    author: "Austin Kleon",
    color: "#9fa8da",
    colorFont: "black",
    comment: "...",
    description: "...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 12,
    img: "./img/roubecomoumartista.jpg",
    rating: "4",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "Roube Como um Artista",
  },
  {
    author: "Yuval Noah Harari",
    color: "#7986cb",
    colorFont: "black",
    comment: "A fascinating journey to discover more about our past and about ourselves.",
    description: "Sapiens: A Brief History of Humankind is a book by Yuval Noah Harari, first published in Hebrew in Israel in 2011 based on a series of lectures Harari taught...",
    font: '"Dancing Script", cursive',
    heigth: "120px",
    id: 13,
    img: "./img/sapiens.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/dark-exa.png)",
    title: "Sapiens: A Brief History of Humankind"
  },
  {
    author: "Ta-Nehisi Coates",
    color: "#3949ab",
    colorFont: "white",
    comment: "Amazing book.",
    description: "Between the World and Me is a 2015 nonfiction book written by American author Ta-Nehisi Coates and published by Spiegel & Grau. It is written as a letter to the author's teenage son about the feelings, symbolism...",
    font: '"Dancing Script", cursive',
    heigth: "100px",
    id: 14,
    img: "./img/betweentheworldandme.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/dark-exa.png)",
    title: "Between the World and Me",
  },
  {
    author: "Ailton Krenak",
    color: "#9fa8da",
    colorFont: "black",
    comment: "...",
    description: "Uma parábola sobre os tempos atuais, por um de nossos maiores pensadores indígenas. Ailton Krenak nasceu na região do vale do rio Doce, um lugar cuja ecologia se encontra profundamente afetada pela atividade de extração mineira...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 15,
    img: "./img/ideiasparaadiarofimdomundo.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "Ideias Para Adiar o Fim do Mundo",
  },
  {
    author: "Matthew Walker",
    color: "#9fa8da",
    colorFont: "black",
    comment: "...",
    description: "Why We Sleep: The New Science of Sleep and Dreams is a popular science book about sleep by the neuroscientist and sleep researcher, Matthew Walker...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 16,
    img: "./img/whywesleep.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "Inferno",
  },
  {
    author: "Stanislas Dehaene",
    color: "#9fa8da",
    colorFont: "black",
    comment: "...",
    description: "Em breve uma descrição...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 17,
    img: "./img/howwelearn.jpg",
    rating: "4",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "How We Learn",
  },
  {
    author: "Michelle Obama",
    color: "#9fa8da",
    colorFont: "black",
    comment: "Reading",
    description: "Becoming is the memoir of former First Lady of the United States Michelle Obama, published in 2018. Described by the author as a deeply personal experience, the book talks about her roots and how she found her voice, as well as her time in the White House...",
    font: '"Akaya Telivigala", cursive',
    heigth: "130px",
    id: 18,
    img: "./img/becoming.jpg",
    rating: "5",
    texture: "url(https://www.transparenttextures.com/patterns/binding-light.png)",
    title: "Becoming",
  }
];
